import React, { useState } from "react";
import { Link } from "gatsby";
import Layout from "../components/layout";
import useWindowDimensions from "../components/hooks/useWindowDimensions";
import useScrollPosition from "../components/hooks/useScrollPosition";
import Img from "gatsby-image";
import DateFormat from "../components/common/dateformat";
import ScrollToTop from "react-scroll-up";
import ArrowUp from "../components/common/arrowup";

import "../styles/templates/portfolioItem.sass";

// const getMarkup = (htmlAst) => {
//   let html = "";
//   return htmlAst.children.map((element, index) => {
//     if (element.type === "text") return (html += "\n");

//     html += `<${element.tagName}>`;
//     element.children.map((item, index) => {
//       if (item.type === "text" && element.length === 1)
//         return (html += `<${element.tagName}>${item.value}</${element.tagName}>`);

//       if (item.type !== "element") return console.log("1. watch it", element);
//       console.log(element);

//       item.children.map((item, index) => {
//         if (item === 1) return (html += `<${element.tagName}>`);
//       });
//     });
//   });
// };

const PortfolioItem = ({ pageContext, location }) => {
  const { slugref, node } = pageContext;
  const { home } = pageContext.data.data;
  const { innerHeight, innerWidth } = useWindowDimensions();
  const { relativeScrollHeight } = useScrollPosition();
  const [toggle, setToggle] = useState(false);

  if (typeof window === `undefined` || typeof document === "undefined")
    return <></>;
  return (
    <Layout
      location={location}
      data={home}
      toggle={toggle}
      setToggle={setToggle}
      slugref={slugref}
      relScrollHeight={relativeScrollHeight}
      color={node.colormain.hex}
    >
      {console.log(
        window.document.body.offsetHeight -
          document.documentElement.offsetHeight
      )}
      <section
        className="pageOption posabs"
        style={{
          height:
            window.document.body.offsetHeight -
            document.documentElement.offsetHeight +
            innerHeight -
            100,
          zIndex: -1,
        }}
        data-sal="fade"
        data-sal-delay={100}
        data-sal-easing="ease"
        data-sal-duration="1000"
      >
        <div
          className="option bg-back"
          data-inf=""
          style={{
            width: `${0.5 * innerWidth +
              324.7543 +
              (0.725 * innerHeight - 456.75)}px`,
            left: 0,
            height: innerHeight - 109,
            backgroundColor: node.colorminor.hex,
          }}
        ></div>
        <div
          className="option bg-back"
          style={{
            width: `${0.5 * innerWidth +
              324.7543 +
              (0.725 * innerHeight - 456.75)}px`,
            right: 0,
            backgroundColor: node.colorminor.hex,
          }}
        ></div>
      </section>
      <section>
        <article className="portfolioItem___textContainer">
          <div className="portfolioItem___goback">
            <Link to={"/portfolio"}>&laquo; Portfolio</Link>
          </div>
          <div className="portfolioItem___headertext">
            <div className="inline">
              <h1
                className="center"
                data-sal="slide-up"
                data-sal-delay={300}
                data-sal-easing="ease"
                data-sal-duration="1000"
              >{`${node.titel}`}</h1>
              <h2
                data-sal="slide-up"
                data-sal-delay={600}
                data-sal-easing="ease"
                data-sal-duration="1000"
              >
                {node.subtitel}
              </h2>
              <h3
                className="center"
                data-sal="slide-up"
                data-sal-delay={900}
                data-sal-easing="ease"
                data-sal-duration="1000"
              >
                {`${DateFormat(
                  "%b %e, %Y",
                  new Date(node.meta.createdAt),
                  "de"
                )} · ${
                  node.contentNode.childMarkdownRemark.timeToRead
                } min read`}
              </h3>
            </div>
          </div>
          <div
            className="portfolioItem___imageWrap"
            data-sal="slide-up"
            data-sal-delay={0}
            data-sal-easing="ease"
            data-sal-duration="1000"
          >
            <Img
              className="portfolioItem___image"
              fluid={node.coverImage.fluid}
              loading="lazy"
            />
          </div>
          <div
            className="portfolioItem___contentWrap"
            dangerouslySetInnerHTML={{
              // __html: getMarkup(node.contentNode.childMarkdownRemark.htmlAst),
              __html: node.contentNode.childMarkdownRemark.html,
            }}
            data-sal="slide-up"
            data-sal-delay={1500}
            data-sal-easing="ease"
            data-sal-duration="1000"
          ></div>
        </article>
      </section>
      <ScrollToTop
        style={{
          position: "fixed",
          bottom: 50,
          right: 100,
          cursor: "pointer",
          transitionDuration: "0.2s",
          transitionTimingFunction: "linear",
          transitionDelay: "0s",
          zIndex: "10",
        }}
        showUnder={160}
      >
        <ArrowUp color={node.colormain.hex} />
      </ScrollToTop>
    </Layout>
  );
};

export default PortfolioItem;

import { useState, useMemo, useEffect } from "react";
import _ from "lodash";

function getDocumentOffsetHeight() {
  if (typeof document === "undefined" || typeof window === "undefined")
    return 1;

  return (
    window.document.body.offsetHeight - document.documentElement.offsetHeight
  );
}

export default function ScrollPosition() {
  const [scrollPosition, setSrollPosition] = useState(0);

  const handleScroll = () => {
    const position = typeof window !== "undefined" ? window.pageYOffset : 0;
    setSrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", _.debounce(handleScroll, 10), {
      passive: true,
    });
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return useMemo(
    () => ({
      scrollPosition,
      documentOffsetHeight: getDocumentOffsetHeight(),
      relativeScrollHeight: scrollPosition / getDocumentOffsetHeight(),
    }),
    [scrollPosition]
  );
}
